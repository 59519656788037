<template>
  <div class="bg">
    <div class="top">
      <h1>中国人民解放军总医院301医院</h1>
      <div class="tagbox">
        <span class="tag"> 三甲医院 </span>
      </div>
      <div class="sumbox">
        <p><span>35科室</span><span>350位医生</span></p>
      </div>
      <div class="position">
        <span><van-icon name="location-o" /> 湖北省武汉市洪山区北京路58号</span>
      </div>
      <div class="info" @click="goinfo">
        <div class="sl">
          同济医院光谷院区地处光谷新城核心区域，总体规划36万平方米，由德国著名设计师设计，集智能化、数字化和人性化服务于一体。...
        </div>
        <img src="../../../assets/img/right.png" alt="" srcset="" />
      </div>
    </div>
    <div class="h1">医院科室</div>
    <van-tree-select
      :items="items"
      :active-id.sync="activeId"
      :main-active-index.sync="activeIndex"
      selected-icon="none"
      @click-item='godoctorlist'
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      items: [
        {
          text: "外科",
          dot: false,
          className: "my-class",
          children: [
            {
              text: "头科",
              id: 1,
              disabled: false,
            },
            {
              text: "腿科",
              id: 2,
            },
            {
              text: "头科2",
              id: 3,
              disabled: false,
            },
            {
              text: "腿科1",
              id: 4,
            },
          ],
        },
        {
          text: "外科",
          dot: false,
          className: "my-class",
          children: [
            {
              text: "头科",
              id: 1,
              disabled: false,
            },
            {
              text: "腿科",
              id: 2,
            },
            {
              text: "头科2",
              id: 3,
              disabled: false,
            },
            {
              text: "腿科1",
              id: 4,
            },
          ],
        },
        {
          text: "外科",
          dot: false,
          className: "my-class",
          children: [
            {
              text: "头科",
              id: 1,
              disabled: false,
            },
            {
              text: "腿科",
              id: 2,
            },
            {
              text: "头科2",
              id: 3,
              disabled: false,
            },
            {
              text: "腿科1",
              id: 4,
            },
          ],
        },
        {
          text: "外科",
          dot: false,
          className: "my-class",
          children: [
            {
              text: "头科",
              id: 1,
              disabled: false,
            },
            {
              text: "腿科",
              id: 2,
            },
            {
              text: "头科2",
              id: 3,
              disabled: false,
            },
            {
              text: "腿科1",
              id: 4,
            },
          ],
        },
        {
          text: "外科",
          dot: false,
          className: "my-class",
          children: [
            {
              text: "头科",
              id: 1,
              disabled: false,
            },
            {
              text: "腿科",
              id: 2,
            },
            {
              text: "头科2",
              id: 3,
              disabled: false,
            },
            {
              text: "腿科1",
              id: 4,
            },
          ],
        },
      ],
      activeId: 1,
      activeIndex: 0,
    };
  },
  methods: {
    godoctorlist(){
       this.$router.push({ name: "doctorlist" });
    },
    goinfo(){
       this.$router.push({ name: "hospitalinfo" });

    }
  },
};
</script>
<style lang="less" scoped>
.van-sidebar-item--select::before { display: none;}
.van-sidebar-item--select { background: #4abffe; color:#fff} 
.van-tree-select__item--active { color: #4abffe;} 
.bg {
  .top {
    width: 375px;
    height: 284px;
    background: #1daefc;
    padding: 16px;
    text-align: left;
    h1 {
      color: #ffffff;
      font-size: 18px;
      line-height: 49px;
      font-weight: 400;
    }
    .tagbox {
      margin-bottom: 10px;
      .tag {
        display: inline-block;
        padding: 4px 10px;
        color: rgba(29, 174, 252, 1);
        background: #e2f5ff;
        border-radius: 4px;
      }
    }
    .sumbox {
      p {
        color: #e2f5ff;
        margin-bottom: 10px;
        font-size: 16px;
        text-align: left;
        &.f14 {
          font-size: 14px;
        }
        span {
          margin-right: 32px;
          font-size: 14px;
        }
      }
    }
    .position {
      color: #e2f5ff;
      margin-bottom: 25px;
    }
    .info {
      width: 343px;
      height: 88px;
      background: #4abffe;
      border-radius: 8px;
      padding: 16px;
      color: #fff;
      position: relative;
      img {
        position: absolute;
        width: 6px;
        height: 12px;
        right: 19px;
        top: 50%;
        transform: translateY(-50%);
      }
      .sl {
        width: 286px;
      }
    }
  }
  .h1 {
    padding: 16px;
    font-size: 16px;
    color: rgba(45, 45, 45, 1);
    text-align: left;
    background: #fff;
  }
}
</style>
